<template>
  <v-container fluid class="vehicles-page">
    <v-row>
      <v-col class="mb-5" cols="auto" md="12" sm="12">
        <v-card>
          <div :id="window.width < 600 ? 'vehicle-page-step-0' : ''"></div>
          <v-data-table
            :footer-props="{
              pageText: `{0}-{1} ${$t('tables.of')} {2}`,
              itemsPerPageText: $t('tables.vehiclesByPage'),
              itemsPerPageOptions: [5, 10, 20, 50, 100],
            }"
            :headers="vehiclesHeaders"
            :disable-sort="tourActive"
            :class="tourActive ? 'no-pointers' : ''"
            :no-data-text="$t('tables.noDataText')"
            :no-results-text="$t('tables.noDataText')"
            :items="vehicles"
            :search="searchVehicleString"
            :item-class="addCustomClass"
            class="elevation-1"
            @click:row="showVehicleDetailsDialog"
            multi-sort>
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                  {{ $t('tables.vehicles') }}
                </v-toolbar-title>

                <v-divider class="mx-4" inset vertical></v-divider>

                <v-text-field
                  id="vehicle-page-step-1"
                  v-model="searchVehicleString"
                  append-icon="mdi-magnify"
                  hide-details
                  :label="$t('tables.search')"
                  single-line></v-text-field>

                <p :id="window.width > 599 ? 'vehicle-page-step-0' : ''" class="first-step"></p>

                <v-spacer />

                <v-dialog v-model="vehicleDialog" max-width="700px" :key="vehicleDialog">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mb-2" color="primary" dark v-bind="attrs" v-on="on" id="vehicle-page-step-2">
                      {{ $t('buttons.add') }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ $t(vehicleFormTitle) }}
                      <v-spacer />
                      <v-icon class="mdi mdi-close" style="color: white" @click="vehicleDialog = false"></v-icon>
                    </v-card-title>

                    <template v-if="editedVehicleItem.id">
                      <vehicle-form
                        ref="vehicleFormRef"
                        :user="user"
                        :editedVehicleItem="editedVehicleItem"
                        :vehicleClasses="vehicleClasses"
                        @closeVehicleEdit="closeVehicleEdit"
                        @loadAllVehicles="loadAllVehicles"
                        @savedVehicleSuccessfully="handleSavedVehicle"
                        @errorOccurred="errorOccurred" />
                    </template>
                    <template v-else>
                      <vehicle-form
                        ref="vehicleFormRef"
                        :user="user"
                        :editedVehicleItem="editedVehicleItem"
                        :vehicleClasses="vehicleClasses"
                        @closeVehicleEdit="closeVehicleEdit"
                        @loadAllVehicles="loadAllVehicles"
                        @savedVehicleSuccessfully="handleSavedVehicle"
                        @errorOccurred="errorOccurred" />
                    </template>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="vehicleDetailsDialog" max-width="700px">
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ $t(vehicleFormTitle) }}
                      <v-spacer />
                      <v-icon class="mdi mdi-close" style="color: white" @click="closeVehicleDetailsEdit"></v-icon>
                    </v-card-title>

                    <template>
                      <v-tabs v-model="vehicleTab">
                        <v-tab>{{ $t('vehiclesPage.tabs.general') }}</v-tab>
                        <v-tab>{{ $t('vehiclesPage.tabs.transfers') }}</v-tab>
                        <v-tab>{{ $t('vehiclesPage.tabs.costs') }}</v-tab>
                        <v-tab>{{ $t('vehiclesPage.tabs.damages') }}</v-tab>
                      </v-tabs>

                      <v-tabs-items v-model="vehicleTab">
                        <v-tab-item key="1">
                          <vehicle-general-info
                            ref="vehicleFormRef"
                            :vehicle="vehicle"
                            :vehicleClasses="vehicleClasses"
                            :user="user" />
                        </v-tab-item>
                        <v-tab-item key="2">
                          <tabular-view :vehicleId="vehicle.id" :vehiclesPage="true"></tabular-view>
                        </v-tab-item>
                        <v-tab-item key="3">
                          <vehicle-expenses :vehicles="vehicles" :vehicleId="vehicle.id" />
                        </v-tab-item>
                        <v-tab-item key="4">
                          <vehicle-maintenances :vehicles="vehicles" :vehicleId="vehicle.id" />
                        </v-tab-item> </v-tabs-items
                    ></template>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="vehicleDialogDelete" max-width="650">
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ editedVehicleItem.plate_number }}
                      <v-spacer />
                      <v-icon class="mdi mdi-close" style="color: white" @click="closeVehicleDelete"></v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <p>
                          {{ $t('vehiclesPage.deleteMessage') }}
                        </p>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn class="primary" text @click="deleteVehicleItemConfirm">
                        {{ $t('buttons.yes') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="dialogImage" height="auto" width="auto">
                  <v-card>
                    <v-img
                      v-if="itemImage"
                      :src="itemImage"
                      max-height="600"
                      max-width="600"
                      style="background-size: cover"></v-img>
                    <v-img
                      v-else
                      aspect-ratio="2"
                      contain
                      height="250px"
                      src="@/assets/default-user.png"
                      style="background-size: cover"
                      width="250px"></v-img>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on: tooltipOn }">
                      <v-btn text v-on="{ ...on, ...tooltipOn }">
                        <v-icon> mdi-dots-vertical </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t('buttons.actions') }}
                    </span>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="showVehicleDetailsDialog(item)">
                        <v-icon class="mx-1" small v-bind="attrs">mdi-arrow-expand</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.show') }}
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="editVehicleItem(item)">
                        <v-icon class="mx-1" small v-bind="attrs">mdi-pencil</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.edit') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.edit') }}</span>
                  </v-tooltip>

                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="showImage(item)">
                        <v-icon class="mx-1" small v-bind="attrs">mdi-fit-to-screen</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.showImage') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.showImage') }}</span>
                  </v-tooltip>

                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="makeVehicleActive(item)">
                        <v-icon v-if="item.active" class="mx-1" small v-bind="attrs">mdi-toggle-switch-outline</v-icon>
                        <v-icon v-if="!item.active" class="mx-1" small v-bind="attrs"
                          >mdi-toggle-switch-off-outline</v-icon
                        >
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ item.active ? $t('buttons.deactivate') : $t('buttons.activate') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ item.active ? $t('tooltips.deactivate') : $t('tooltips.activate') }}</span>
                  </v-tooltip>

                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="deleteItem(item)">
                        <v-icon class="mx-1" small v-bind="attrs">mdi-trash-can-outline</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.delete') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.delete') }}</span>
                  </v-tooltip>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <div class="tourStyle">
      <v-tour name="vehiclesTour" :steps="stepsTour" :callbacks="myCallbacks" :options="options"></v-tour>
    </div>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { vehiclesHeaders } from '@/mixins/data-table-headers';
import { defaultVehicle } from '@/mixins/default-items';
import VehicleForm from './VehicleForm.vue';
import VehicleExpenses from '../../expenses/ExpensesList.vue';
import VehicleMaintenances from '../VehicleMaintenances.vue';
import TabularView from '../../drivings/TabularView.vue';
import VehicleGeneralInfo from '@/views/pages/vehicles/VehicleGeneralInfo.vue';
import { getAllTourSteps, vehiclesPageSteps } from '@/mixins/onboarding-tutorial-steps';
import { mapState } from 'vuex';
import store from '@/store';

export default {
  name: 'VehiclePage',
  components: { VehicleForm, VehicleGeneralInfo, TabularView, VehicleExpenses, VehicleMaintenances },
  data() {
    return {
      searchVehicleString: '',
      vehicleDialog: false,
      vehicleDetailsDialog: false,
      vehicleDialogDelete: false,
      vehicles: [],
      vehicleFormTitle: 'vehiclesPage.newItem',
      editedVehicleItem: {},
      vehicle: {},
      vehicleClasses: [],
      dialogImage: false,
      myCallbacks: {
        onStop: this.onComplete,
        onNextStep: this.onNextStep,
        onSkip: this.onSkip,
      },
      itemImage: null,
      vehicleTab: null,
      steps: [],
      visitedStepsArray: [],
      stepsTour: [],
      window: {
        width: 0,
      },
      options: {
        labels: {
          buttonSkip: i18n.t('buttons.skip'),
          buttonPrevious: '<< ' + i18n.t('buttons.prev'),
          buttonNext: i18n.t('buttons.next') + ' >>',
          buttonStop: i18n.t('buttons.finish'),
        },
        highlight: true,
      },
      user: {},
    };
  },
  created() {
    this.editedVehicleItem = Object.assign({}, defaultVehicle);
    this.loadAllVehicles();
    this.loadAllVehicleClasses();
    window.addEventListener('resize', this.handleResize);
    this.handleResize();

    this.user = this.$store.getters['auth/user'];
  },
  computed: {
    ...mapState('onboardingTutorial', ['tourActive']),
    vehiclesHeaders() {
      return vehiclesHeaders(i18n, store);
    },
  },
  methods: {
    async loadAllVehicles() {
      await this.$store.dispatch('vehicles/getAllVehicles').then((res) => {
        this.vehicles = res.data.map((e) => {
          e.active = parseInt(e.active);
          return e;
        });

        this.setStepsAndStartTour();
      });
    },

    async loadAllVehicleClasses() {
      await this.$store.dispatch('vehicleClasses/getAllVehicleClasses').then((res) => {
        this.vehicleClasses = res.data;
      });
    },

    editVehicleItem(item) {
      this.editedVehicleItem = this.vehicles.indexOf(item);
      this.editedVehicleItem = Object.assign({}, item);
      this.vehicleFormTitle = item.plate_number;
      this.editedVehicleItem.vehicle_class_id = parseInt(item.vehicleClass?.id);
      this.editedVehicleItem.year_of_manufacture = item.year_of_manufacture ? parseInt(item.year_of_manufacture) : null;
      this.editedVehicleItem.number_of_passengers = item.number_of_passengers ? item.number_of_passengers : null;
      this.editedVehicleItem.price_per_km = item.price_per_km ? Number(item.price_per_km) : null;
      this.editedVehicleItem.price_per_hour = item.price_per_hour ? Number(item.price_per_hour) : null;
      this.editedVehicleItem.fuel_consumption = item.fuel_consumption ? Number(item.fuel_consumption) : null;
      this.editedVehicleItem.price_per_waiting_hour = item.price_per_waiting_hour
        ? Number(item.price_per_waiting_hour)
        : null;
      this.editedVehicleItem.licence = item.licence;
      this.vehicleDialog = true;
    },

    deleteItem(item) {
      this.editedVehicleItem = this.vehicles.indexOf(item);
      this.editedVehicleItem = Object.assign({}, item);
      this.vehicleDialogDelete = true;
    },

    async deleteVehicleItemConfirm() {
      await this.$store
        .dispatch('vehicles/deleteVehicle', this.editedVehicleItem)
        .then((res) => {
          if (res.success) {
            this.loadAllVehicles();
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.deletedSuccessfully'), color: 'green' });
            this.closeVehicleDelete();
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        })
        .finally(() => {
          this.closeVehicleDelete();
        });
    },

    showImage(item) {
      this.dialogImage = true;
      if (item.image_path) {
        if (!item.image_path.includes('https://') && !item.image_path.includes('http://')) {
          this.itemImage = process.env.VUE_APP_BACKEND_URL.concat(item.image_path);
          this.itemImage = this.itemImage.replace('public', 'storage');
        } else {
          this.itemImage = item.image_path;
        }
      } else {
        this.itemImage = null;
      }
    },

    closeVehicleEdit() {
      this.vehicleDialog = false;
      this.$nextTick(() => {
        this.editedVehicleItem = Object.assign({}, defaultVehicle);
      });

      this.$refs.vehicleFormRef.resetForm();
      this.vehicleFormTitle = 'vehiclesPage.newItem';
      this.vehicleTab = null;
    },

    closeVehicleDelete() {
      this.vehicleDialogDelete = false;
      this.$nextTick(() => {
        this.editedVehicleItem = Object.assign({}, defaultVehicle);
      });
    },

    async makeVehicleActive(item) {
      await this.$store
        .dispatch('vehicles/changeVehicleActiveStatus', item)
        .then(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.activeStatusChanged'), color: 'green' });
          item.active = !item.active;
        })
        .catch(() => {
          item.active = !item.active;
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        });
    },

    errorOccurred(text) {
      this.$store.dispatch('showSnackbar', { text: text, color: 'red' });
    },

    showVehicleDetailsDialog(item) {
      this.vehicle = item;

      this.vehicleFormTitle = item.plate_number;
      this.vehicle.vehicle_class_id = parseInt(item.vehicleClass?.id);
      this.vehicle.year_of_manufacture = item.year_of_manufacture ? parseInt(item.year_of_manufacture) : null;
      this.vehicle.number_of_passengers = item.number_of_passengers ? item.number_of_passengers : null;
      this.vehicle.price_per_km = item.price_per_km ? Number(item.price_per_km) : null;
      this.vehicle.price_per_hour = item.price_per_hour ? Number(item.price_per_hour) : null;
      this.vehicle.fuel_consumption = item.fuel_consumption ? Number(item.fuel_consumption) : null;
      this.vehicle.price_per_waiting_hour = item.price_per_waiting_hour ? Number(item.price_per_waiting_hour) : null;
      if (item.image_path) {
        if (!item.image_path.includes('https://') && !item.image_path.includes('http://')) {
          this.vehicle.itemImage = process.env.VUE_APP_BACKEND_URL.concat(item.image_path).replace('public', 'storage');
        } else {
          this.vehicle.itemImage = item.image_path;
        }
      } else {
        this.vehicle.itemImage = null;
      }
      this.vehicleDetailsDialog = true;
    },

    closeVehicleDetailsEdit() {
      this.vehicleDetailsDialog = false;
      this.vehicleTab = null;
      this.vehicleFormTitle = 'vehiclesPage.newItem';
    },

    handleSavedVehicle() {
      this.$store.dispatch('showSnackbar', {
        text: i18n.t('snackbar.savedSuccessfully'),
        color: 'green',
      });
    },

    async setStepsAndStartTour() {
      if (this.$store.state.auth.role !== 1 && !this.tourActive) {
        await this.setSteps();
        if (this.stepsTour?.length > 0) {
          this.startTour();
        }
      }
    },

    setSteps() {
      return new Promise((resolve) => {
        const visitedStepsParsed = JSON.parse(localStorage.getItem('LimoExpressUser'))?.user_tour_logs;

        if (visitedStepsParsed) {
          this.visitedStepsArray = visitedStepsParsed
            .filter((item) => item.component_id === '2')
            ?.map((item) => item.step_id);
          this.steps = vehiclesPageSteps();
          if (this.visitedStepsArray?.length > 0) {
            this.stepsTour = this.steps.filter((step) => !this.visitedStepsArray.includes(step.target));
          } else {
            this.stepsTour = this.steps;
          }
        } else {
          this.stepsTour = [];
          let user = this.$store.getters['auth/user'];
          user.user_tour_logs = getAllTourSteps();

          this.$store.dispatch('auth/setUser', user);
          localStorage.setItem('LimoExpressUser', JSON.stringify(user));
        }

        resolve();
      });
    },

    startTour() {
      this.$tours['vehiclesTour'].start();
      this.$store.dispatch('onboardingTutorial/toggleTour', true);
    },

    onComplete() {
      this.vehicleDialog = false;
      this.$store.dispatch('onboardingTutorial/toggleTour', false);
      this.setStepAsVisited(this.steps.pop()?.target?.split('-').pop());
    },

    onSkip() {
      this.$store.dispatch('onboardingTutorial/toggleTour', false);

      const body = {
        objects: this.steps.map((item) => ({
          component_id: '2',
          step_id: item.target,
        })),
      };

      this.$store.dispatch('onboardingTutorial/sendVisitedStep', body);
      const lastCharsOfTarget = this.steps.map((step) => step.target.slice(-1));
      lastCharsOfTarget.forEach((step) => this.updateStorage(step));
    },

    onNextStep(currentStep) {
      const currentStepId = this.stepsTour[currentStep]?.targetId;
      this.setStepAsVisited(currentStepId);
    },

    updateStorage(currentStep) {
      const newVisitedStep = {
        component_id: '2',
        step_id: `#vehicle-page-step-${currentStep}`,
      };

      let user = this.$store.getters['auth/user'];

      if (user) {
        if (user.user_tour_logs?.length > 0) {
          user.user_tour_logs.push(newVisitedStep);
        } else {
          user.user_tour_logs = [newVisitedStep];
        }
      }

      this.$store.dispatch('auth/setUser', user);
      localStorage.setItem('LimoExpressUser', JSON.stringify(user));
    },

    setStepAsVisited(step) {
      const body = {
        objects: [
          {
            component_id: '2',
            step_id: `#vehicle-page-step-${step}`,
          },
        ],
      };
      this.$store.dispatch('onboardingTutorial/sendVisitedStep', body);
      this.updateStorage(step);
    },

    handleResize() {
      this.window.width = window.innerWidth;
    },

    addCustomClass(item) {
      return item.active === true || item.active == 1 ? 'active-row' : 'inactive-row';
    },
  },

  beforeDestroy() {
    document.body.classList.remove('v-tour--active');
    this.$store.dispatch('onboardingTutorial/toggleTour', false);
  },

  watch: {
    vehicleDialog(val) {
      val || this.closeVehicleEdit();
    },
    vehicleDetailsDialog(val) {
      val || this.closeVehicleDetailsEdit();
    },
    tourActive() {
      this.setStepsAndStartTour();
    },
    '$store.state.onboardingTutorial.tourReset': {
      handler() {
        if (this.$store.state.onboardingTutorial.tourReset) {
          this.setStepsAndStartTour(); // Function to start your Vue Tour

          // Clear the tour reset flag to avoid repeat triggers
          this.$store.dispatch('onboardingTutorial/clearTourReset');
        }
      },
    },
  },
};
</script>

<style>
.vehicles-page {
  .no-pointers {
    pointer-events: none;
  }

  .v-step {
    inset: 0 0 auto -10px !important;
  }

  .first-step {
    margin-top: 250px;
  }
}
</style>
